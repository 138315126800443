import { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Column from '../../../components/Column';
import TagControlBar from '../components/tags/TagControlBar';
import TagTable from '../components/tags/TagTable';

interface ITagSectionProps { }

const TagSection: FunctionComponent<ITagSectionProps> = () => {

    //const [selectedTags, setSelectedTags] = useState<string[]>([])
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false)

    const [controlBarHeight, setControlBarHeight] = useState<number>(0);
    const controlBarRef = useRef<HTMLDivElement>(null);

    const updateControlBarHeight = () => {
        if (controlBarRef.current) {
            const height = controlBarRef.current.offsetHeight - 1;
            setControlBarHeight(height);
        }
    };
    useEffect(() => {
        updateControlBarHeight();
    }, [controlBarRef.current]);

    return <Container>
        <Column>
            <StickyControlBar ref={controlBarRef}>
                <TagControlBar
                    isAllSelectedProps={{isAllSelected, setIsAllSelected}}
                />
            </StickyControlBar>
            <TagTable 
                controlBarHeight={controlBarHeight} 
                isAllSelectedProps={{isAllSelected, setIsAllSelected}}
            />
        </Column>
    </Container>
}

const Container = styled.div`
    width: 100%;
    padding: 0px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    overflow-y: auto;
`

const StickyControlBar = styled.div`
  position: sticky;
  top: 0;
  z-index: 1005;
  background: ${({theme: { tertiaryColor }}) => tertiaryColor};
  padding-top: 16px;
`;

export default TagSection
