import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import ColorCircle from '../../../../components/ColorCircle';
import { styles } from '../../../../styles/themes/style';
import ActionText from '../../../../components/ActionText';
ChartJS.register(ArcElement, Tooltip);

interface IDashboardChartProps {
    dataSet: any,
    data: any
    title: string
    total: number,
    dataLineClickHandler: (e: any) => void
}

const DashboardChart: FunctionComponent<IDashboardChartProps> =({ data, dataSet, total, title, dataLineClickHandler }) => {

    return (
      <>
        <ChartArea>
          <Pie data={dataSet} />
        </ChartArea>
        <>
            <LegendTitle>{ title }</LegendTitle>
                <Container>
                    { data && data.map((element: any, index: number) => 
                        <ListLine key={index}>
                            <DataLine onClick={() => dataLineClickHandler(element?._id ?? 'Not disclosed')}> 
                                <DataLegend>
                                    <ColorCircle color={ dataSet?.datasets?.[0]?.backgroundColor[index] } />
                                    <ActionText $capitalize variable onClickHandler={() => {}}>{ element?._id != null && element?._id !== '' ? element?._id : 
                                        (element?._id === '' ? 'To be updated' : 'Not disclosed') }
                                    </ActionText>
                                </DataLegend>
                                <span> { Math.round(element.count/total*100) }% </span>
                            </DataLine>
                        </ListLine>)}
                </Container>
        </>
      </>
    )
}

const ChartArea = styled.div`
    display: flex;
    height: 200px;
    max-width: 200px;
    min-width: 150px;
    margin-bottom: 20px;
`
const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    background: ${({theme: {secondaryColor}}) => secondaryColor};
    overflow-y: auto;
`
const LegendTitle = styled.div`
    display: flex;
    width: 100%;
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: ${({theme: {quaternaryColor}}) => quaternaryColor};
    margin-bottom: 10px;
    text-transform: capitalize;
`
const ListLine = styled.div`
    display: flex;
    flex-direction: column;
    width: 99%;
    align-items: flex-start;
`
const DataLine = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    transition: all .2s ease;
    color: ${({theme: {primaryColor}}) => primaryColor};
    &:hover{
        cursor: pointer;
        color: ${styles.colors.primary600};
    }
`
const DataLegend = styled.span`
    display: flex;
    align-items: baseline;
`
export default DashboardChart