import React, { FormEvent, FunctionComponent, ReactNode, useCallback, useState } from 'react'
import styled from 'styled-components'
import TabsView from '../../../../components/TabsView'
import BackgroundUpdate from './extension/BackgroundUpdate'
import LinkedinSidebar from './linkedin/LinkedinSidebar'

interface ILinkedinProps { }

const Linkedin: FunctionComponent<ILinkedinProps> = () => {
    const [ selectedSlug, setSelectedSlug ] = useState<string>('linkedinSidebar')

    const LinkedinTabs = [{
        slug: "linkedinSidebar",
        title: "LinkedIn sidebar",
        selected: true
    }]
    
    const renderComponent: {[key: string]: ReactNode} = {
        linkedinSidebar: <LinkedinSidebar />,
    }

    return <>
        <Container>
            <TabsView onSwitchTab={()=>{}} tabs={LinkedinTabs}>
                {renderComponent[selectedSlug]}
            </TabsView>
            
        </Container>
        <MobileContainer>
                {renderComponent[selectedSlug]}
            </MobileContainer>
        </>
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y:auto;
    height: 100%;

    @media (max-width: 786px) {
        display: none;
    }
`
const MobileContainer = styled.div`
    display: none;

    @media (max-width: 786px) {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
`

export default Linkedin
