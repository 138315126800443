import { useAxios } from "../useAxios"
import { useAuthStore } from "../../../stores/auth/useAuthStore"
import { IAutoTag, ITag } from "../../../interfaces/models/connection"
import { ICreateTag } from "../../../interfaces/models/tag"

export interface IConnectionsQueryParams {
    page?: number,
    pageSize?: number,
    sort?: string,
    searchValues?: ISearchValues[],
    searchByFollowers?: number,
    searchByMaxFollowers?: number,
    isHidden?: string,
    note?: string,
    message?: string,
    tags?: ITag[],
    excludeTags?: ITag[],
    noTags?: boolean,
    industry?: string,
    isFollowing?: string,
    contactInfo?: string,
    connectedAtFrom?: string,
    connectedAtTo?: string,
    birthDateFrom?: string,
    birthDateTo?: string,
    lastMessagedFrom?: string,
    lastMessagedTo?: string,
    conversationStatus?: string,
    onlyLast?: boolean,
    languages?: string[],
    tagCondition?: string,
    languageCondition?: string,
    isExcludeMode?: boolean,
    autoTagsArray?: IAutoTag[],
}

export interface ISearchValues {
    searchValue?: string,
    searchByName?: string,
    searchByCompany?: string,
    searchByHeadline?: string,
    searchByLocation?: string,
    searchByJobTitle?: string,
    searchByNote?: string,
}


export interface IConnectionsQuerySelectedParams extends IConnectionsQueryParams {
    connectionIds?: string []
}

export interface IApplyTagsPayload { tags: (string | ICreateTag)[] }
export interface IBulkTagsPayload { filters: IConnectionsQuerySelectedParams, tags: (string | ICreateTag)[] }
export interface IRemoveTagsPayload { tags: string[] }
export interface IExportToCSVPayload { includeTags: boolean, includeNotes: boolean, filters: IConnectionsQuerySelectedParams }
export interface ItoggleBulkHideConnectionsPayload { hideConnection: boolean, filters: IConnectionsQuerySelectedParams }
export interface IConnectionProfileId {
    profileId?: string
}

export const useConnectionsRequest = () => {
    const { sendRequest } = useAxios()
    const { store: { authData: { accessToken } } } = useAuthStore()

    const route = '/profiles';

    //GET
    const getConnections = (params: any, slug?: string) => {
        return sendRequest({
            slug: slug ?? 'getConnections',
            url: `${route}/v1`,
            method: 'GET',
            params
        })
    };

    const getConnectionsTotal = (params: any) => {
        return sendRequest({
            slug: 'getConnectionsTotal',
            url: `${route}/v1/total`,
            method: 'GET',
            params
        })
    };

    const getConnectionByProfileId = (_id: IConnectionProfileId) => {
        return sendRequest({
            slug: 'getConnectionByProfileId',
            url: `${route}/${_id}`,
            method: 'GET'
        })
    };

    const getMultipleConditionsFilters = (params: IConnectionsQueryParams) => {
        return sendRequest({
            slug: 'getMultipleConditionsFilters',
            url: `${route}/multipleConditionsFilters`,
            method: 'GET',
            params
        })
    };

    const getExceededDailyLimit = () => {
        return sendRequest({
            slug: 'getExceededDailyLimit',
            url: `${route}/exceeded-daily-limit`,
            method: 'GET',
            all: true
        })
    }

    const getSelectedConnectionsIds = (params: any) => {
        return sendRequest({
            slug: 'getSelectedConnectionsIds',
            url: `${route}/v1/selected`,
            method: 'GET',
            params
        })
    };

    //PUT
    const updateProfileFailed = (profileId: string) => {
        return sendRequest({
            slug: 'updateProfileFailed',
            url: `${route}/${profileId}/updateFailed`,
            method: 'PUT',
            payload: {}
        })
    }

    const updateProfile = (profileId: string, profileData: any) => {
        return sendRequest({
            slug: 'updateProfile',
            url: `${route}/${profileId}/update`,
            method: 'PUT',
            payload: profileData,
            displayToast: false
        })
    }

    //PATCH
    const updateConnection = (connectionId: string, payload: any) => {
        return sendRequest({
            slug: 'updateConnection',
            url: `${route}/${connectionId}`,
            method: 'PATCH',
            payload
        })
    }

    //POST
    const applyTagsSingleConnection = (connectionId: string, payload: IApplyTagsPayload, displayToast?: boolean) => {
        return sendRequest({
            slug: 'applyTag', //maybe add id to listen to loading completed
            url: `${route}/${connectionId}/tags`,
            method: 'POST',
            payload,
            ...(!!displayToast && { displayToast })
        })
    }

    const applyTagsToConnections = (payload: IBulkTagsPayload, displayToast?: boolean) => {
        return sendRequest({
            slug: 'bulkApplyTag', //maybe add id to listen to loading completed
            url: `${route}/bulk/tags/apply`,
            method: 'POST',
            payload,
            ...(!!displayToast && { displayToast })
        })
    }

    //DELETE
    const removeTagsSingleConnection = (connectionId: string, payload: IRemoveTagsPayload, displayToast?: boolean) => {
        return sendRequest({
            slug: 'removeTag', //maybe add id to listen to loading completed
            url: `${route}/${connectionId}/tags`,
            method: 'DELETE',
            payload,
            ...(!!displayToast && { displayToast })
        })
    }

    const removeTagsFromConnections = (payload: IBulkTagsPayload, displayToast?: boolean) => {
        return sendRequest({
            slug: 'bulkRemoveTags',
            url: `${route}/bulk/tags/remove`,
            method: 'DELETE',
            payload,
            ...(!!displayToast && { displayToast })
        })
    }

    const removeConnection = (connectionId: string, removeFromLD?: boolean, displayToast?: boolean) => {
        return sendRequest({
            slug: 'removeConnection',
            url: `${route}/${connectionId}${removeFromLD ? `?removeConnection=true` : ''}`,
            method: 'DELETE',
            ...(!!displayToast && { displayToast }),
            all: true
        })
    }

    const removeConnections = (payload: any, removeFromLD?: boolean, allSelected?: boolean, displayToast?: boolean) => {
        return sendRequest({
            slug: 'removeConnections',
            url: `${route}/bulk/disconnect/${removeFromLD ? `?removeConnection=true&allSelectedConnection=${allSelected}` : ''}`,
            method: 'DELETE',
            payload,
            ...(!!displayToast && { displayToast }),
            all: true
        })
    }

    const exportToCSV = (payload: IExportToCSVPayload) => {
        return sendRequest({
            slug: 'exportToCSV',
            url: `${route}/download/csv`,
            method: 'POST',
            payload,
            all: true
        })
    }

    const toggleBulkHideConnections = (payload: ItoggleBulkHideConnectionsPayload) => {
        return sendRequest({
            slug: 'toggleBulkHideConnections',
            url: `${route}/bulk/hide`,
            method: 'PATCH',
            payload,
            all: true
        })
    }
    
    const scheduleConnectionsRemoval = (payload: any) => {
        return sendRequest({
            slug: 'scheduleConnectionsRemoval',
            url: `${route}/bulk/scheduleConnectionsRemoval`,
            method: 'PATCH',
            payload,
        })
    }

    const cancelConnectionsRemoval = () => {
        return sendRequest({
            slug: 'cancelConnectionsRemoval',
            url: `${route}/bulk/cancelConnectionsRemoval`,
            method: 'PATCH'
        })
    }

    const connectionsRemovalFinished = (payload: any) => {
        return sendRequest({
            slug: 'connectionsRemovalFinished',
            url: `${route}/bulk/connectionsRemovalFinished`,
            method: 'PATCH',
            payload,
            displayToast: false,
            all: true
        })
    }

    const getNextConnectionsScheduledForRemoval = (params?: any) => {
        return sendRequest({
            slug: 'getNextConnectionsScheduledForRemoval',
            url: `${route}/v1/scheduledForRemoval`,
            method: 'GET',
            params
        })
    };

    return {
        getConnections,
        getConnectionsTotal,
        getMultipleConditionsFilters,
        applyTagsSingleConnection,
        removeTagsSingleConnection,
        getConnectionByProfileId,
        applyTagsToConnections,
        removeTagsFromConnections,
        getExceededDailyLimit,
        updateProfileFailed,
        updateProfile,
        updateConnection,
        exportToCSV,
        toggleBulkHideConnections,
        removeConnection,
        removeConnections,
        scheduleConnectionsRemoval,
        cancelConnectionsRemoval,
        connectionsRemovalFinished,
        getNextConnectionsScheduledForRemoval,
        getSelectedConnectionsIds
    };
}