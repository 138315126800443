import React, { FormEvent, FunctionComponent, useCallback, useState } from 'react'
import styled from 'styled-components'
import Column from '../../../../../components/Column'
import Row from '../../../../../components/Row'
import Text from '../../../../../components/Text'
import { useAuthHandlers } from '../../../../../services/actions/auth/useAuthHandlers'
import { useAuthStore } from '../../../../../stores/auth/useAuthStore'
import { styles } from '../../../../../styles/themes/style'
import ToggleButton from '../../../../../components/ToggleButton'

interface ILinkedinSidebarProps { }

const LinkedinSidebar: FunctionComponent<ILinkedinSidebarProps> = () => {
    const { saveUserPreferencesHandler } = useAuthHandlers()
    const { store: { user } } = useAuthStore()

    const setPersistentLinkedinSidebar = (e: any, isPersistent: boolean) => {
        saveUserPreferencesHandler({persistentLISidebar: isPersistent})
    }

    return <>
            <StyledColumn gap="40px">
                <StyledRow spaceBetween gap='40px'>
                    <Column gap='16px'>
                        <Text $label $capitalize>{'Connection Sidebar'}</Text>
                        <Text $lighter>
                        {'By turning this on, whenever you visit LinkedIn profile of a connection that you have on LeadDelta we will automatically open sidebar for you.'}
                        </Text>
                        <Text $lighter $description>{`Note: Please refresh the LinkedIn page to ensure the latest changes are applied. You can temporarily close the sidebar by clicking the close button and reopen it anytime using the toggle switch.`}</Text>
                    </Column>
                    <ToggleButton onClickHandler={setPersistentLinkedinSidebar} value={user?.preferences?.persistentLISidebar ?? true} />
                </StyledRow>
            </StyledColumn>
            <MobileContainer>
                <StyledColumnMobile>
                    <StyledColumnMobile>
                        <StyledRowMobile>
                            <Text $label $capitalize>{'Connection Sidebar'}</Text>
                        </StyledRowMobile>
                    </StyledColumnMobile>
                    <StyledColumnMobile>
                        <Text $lighter>
                            {'By turning this on, whenever you visit LinkedIn profile of a connection that you have on LeadDelta we will automatically open sidebar for you.'}
                        </Text>
                    </StyledColumnMobile>
                </StyledColumnMobile>
                <Divider />
                <StyledColumnMobile>
                <StyledColumnMobile>
                        <StyledRowMobile>
                            <ToggleButton onClickHandler={setPersistentLinkedinSidebar} value={user?.preferences?.persistentLISidebar ?? true} />
                        </StyledRowMobile>
                    </StyledColumnMobile>
                    <StyledColumnMobile>
                        <Text $lighter>
                            {'The number of connections to be updated (automatically or manually) within 24hrs every time you log into LeadDelta. This limit is set for your own safety and should be used to mimic your regular LinkedIn usage.'}
                        </Text>
                    </StyledColumnMobile>
                    <StyledColumnMobile>
                        <Text $lighter $paragraph3>{`Increasing the recommended limit can lead to triggering LinkedIn automation tools warning.`}</Text>
                    </StyledColumnMobile>
                </StyledColumnMobile>
            </MobileContainer>
        </>
}

const StyledRow = styled(Row)`
    @media (max-width: 786px) {
        display: none;
    }
`
const StyledColumn = styled(Column)`
    @media (max-width: 786px) {
        display: none;
    }
`
const MobileContainer = styled.div`
    display: none;
    width: 100%;

    @media (max-width: 786px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
`
const StyledRowMobile = styled(Row)`
    display: none;
    @media (max-width: 786px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
`
const StyledColumnMobile = styled(Column)<{ marginTop?: string }>`
    display: none;
    @media (max-width: 786px) {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        margin-top: ${({marginTop}) => marginTop ? marginTop : '0px' };
    }
`    
const Divider = styled.div`
  height: 2px;
  margin: 0px 8x 0px 8px;
  background-color: ${styles.colors.uiBackground};
`;
export default LinkedinSidebar
