import { FunctionComponent, useState, useEffect, ReactNode } from "react";
import styled from "styled-components";
import { styles } from "../styles/themes/style";
import DropdownMenu from "../components/dropdown/DropdownMenu";
import DropdownItem from "../components/dropdown/DropdownItem";
import TabsSearch from "../components/TabsSearch";
import Row from "../components/Row";
import Checkbox from "../components/Checkbox";
import { ITag } from "../interfaces/models/connection";
import AppTag from "./AppTag";

export interface IAdvancedFilterOption {
  value: string;
  label: ReactNode;
  id: string;
  selected: boolean;
  object: Object;
}

interface IAutotagsFilterFragmentProps {
  autotagsFilter?: any;
  infoTitle: string;
  inputTitle: string;
  showSearchField?: boolean;
  inputSelect?: boolean;
  resetDisabled?: boolean;
  onSelectedAutotagsHandler: (control: any, newValue: any) => void;
  onResetAutotagsHandler: () => void;
  onSelectedTabHandler: (tab: any) => void;
}

const AutotagsFilterFragment: FunctionComponent<
  IAutotagsFilterFragmentProps
> = ({
  autotagsFilter,
  infoTitle,
  inputTitle,
  inputSelect = false,
  onSelectedAutotagsHandler,
  onResetAutotagsHandler,
  onSelectedTabHandler,
}) => {
  const [tagCondition, setTagCondition] = useState("And");
  const [selectedAutotags, setSelectedAutotags] = useState<
    IAdvancedFilterOption[]
  >([]);

  const dropdownButton = (
    <StyledControlDiv
      numberOfSelectedItems={
        selectedAutotags?.filter((tag) => tag?.selected)?.length ?? 0
      }
    >
      {inputTitle}:&nbsp;
      {selectedAutotags?.some((tag) => tag?.selected) ? (
        <span style={{ color: styles.colors.primary600, fontWeight: "bold" }}>
          {selectedAutotags?.filter((tag) => tag.selected)?.length ?? 0}
          &nbsp;Selected
        </span>
      ) : (
        <span style={{ color: styles.colors.black600, fontWeight: "bold" }}>
          Show All
        </span>
      )}
    </StyledControlDiv>
  );

  useEffect(() => {
    const autotags = [
      {
        _id: "New Connection",
        title: "New Connection",
        color: "#5964ff",
        isForbidden: true,
      },
      {
        _id: "LinkedIn 1st",
        title: "LinkedIn 1st",
        color: "#5964ff",
        isForbidden: true,
      },
      // {
      //   _id: "Disconnected",
      //   title: "Disconnected",
      //   color: "#8C96AD",
      //   isForbidden: true,
      // },
      {
        _id: "LD Removed",
        title: "Disconnected",
        color: "#8C96AD",
        isForbidden: true,
        removedFromLD: true
      },
      {
        _id: "Li Removed",
        title: "Disconnected",
        color: "#8C96AD",
        isForbidden: true,
        removedFromLD: false
      },
      {
        _id: "Removal Scheduled",
        title: "Removal Scheduled",
        color: "#8C96AD",
        isForbidden: true,
      },
      {
        _id: "Prospect",
        title: "Prospect",
        color: "#5964ff",
        isForbidden: true,
      },
      {
        _id: "CSV",
        title: "CSV",
        color: "#5964ff",
        isForbidden: true,
      },
      {
        _id: "Imported",
        title: "Imported",
        color: "#5964ff",
        isForbidden: true,
      },
    ];

    const filterOptions = [
      ...autotags.map((tag: ITag) => ({
        value: tag.title,
        label: <AppTag tag={tag} />,
        id: tag._id,
        selected:
          autotagsFilter?.some((filterTag: any) => filterTag._id === tag._id) ||
          false,
        object: tag,
      })),
    ];

    setSelectedAutotags(filterOptions);
  }, [autotagsFilter]);

  const toggleAutotagsHandler = (event: any, item: IAdvancedFilterOption) => {
    event.stopPropagation();
    let add: boolean;
    let selectedObjects: any[] = [];
    setSelectedAutotags(
      selectedAutotags.map((option) => {
        if (option.id === item.id) {
          add = !option.selected;
          let newOption = { ...option, selected: add };
          if (newOption.selected) selectedObjects.push(newOption.object);
          return newOption;
        }
        if (option.selected) selectedObjects.push(option.object);
        return { ...option };
      })
    );
    onSelectedAutotagsHandler(
      "autotagsFilter",
      selectedObjects.length ? selectedObjects : undefined
    );
  };

  const resetAutotags = () => {
    setSelectedAutotags(
      selectedAutotags.map((option) => {
        return { ...option, selected: false };
      })
    );
    onResetAutotagsHandler();
  };

  return (
    <Wrapper>
      <DropdownMenu title={dropdownButton} autoClose="outside" selectStyle>
        <DropdownItem $nonHoverable>
          <TabsSearch
            inputSelect={inputSelect}
            tabs={[
              { title: "And", slug: "And", selected: tagCondition !== "Or" },
              { title: "Or", slug: "Or", selected: tagCondition === "Or" },
            ]}
            infoTitle={infoTitle}
            selectedItems={selectedAutotags}
            setSelectedTab={(e: any) => {
              setTagCondition(e);
              onSelectedTabHandler(e === "Or" ? "yes" : "no");
            }}
            resetDisabled={!selectedAutotags.length}
            resetSelectedValues={resetAutotags}
          >
            <>
              {selectedAutotags.map((item: IAdvancedFilterOption) => (
                <DropdownItem
                  onClickHandler={(e: any) => toggleAutotagsHandler(e, item)}
                >
                  <Row alignItems gap="10px">
                    <Checkbox checked={item.selected} checkboxId={item.id} />
                    {item.label}
                  </Row>
                </DropdownItem>
              ))}
            </>
          </TabsSearch>
        </DropdownItem>
      </DropdownMenu>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & .actions-dropdown + div {
    width: 100%;
    padding: 16px 15px;
    max-height: 350px;
    overflow: visible;
  }
  & .dropdown-item {
    padding: 0;
  }
`;

const StyledControlDiv = styled.div<{ numberOfSelectedItems: number }>`
  display: flex;
  width: 100%;
  height: 34px;
  padding-left: 6px;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: NunitoSans;
  font-size: 14px;
  line-height: 30px;
  cursor: pointer;
  position: relative;
  user-select: none;
`;

export default AutotagsFilterFragment;
