import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import SvgFragment from "../fragments/SvgFragment";
import Text from "./Text";
import TooltipWrapper from "./TooltipWrapper";

interface IToggleButton {
  value?: boolean;
  flex?: boolean;
  icon?: string;
  label?: string;
  tooltipMessageOn?: string;
  tooltipMessageOff?: string;
  onClickHandler?: (e: any, isToggled: boolean) => void;
}

const ToggleButton: FunctionComponent<IToggleButton> = ({
  value,
  icon,
  label,
  tooltipMessageOn = "",
  tooltipMessageOff = "",
  onClickHandler,
  flex = false
}) => {
  const [isToggled, setIsToggled] = useState(!!value);

  const handleClick = (e: any) => {
    const newToggleState = !isToggled;
    setIsToggled(newToggleState);
    onClickHandler && onClickHandler(e, newToggleState);
  };

  useEffect(() => {
    setIsToggled(!!value);
  }, [value]);

  return (
    <TooltipWrapper
      tooltipText={isToggled ? tooltipMessageOff : tooltipMessageOn}
    >
      <Wrapper flex={flex}>
        <Container onClick={handleClick} isToggled={isToggled}>
          {icon ? (
            <SvgFragment type={icon} />
          ) : isToggled ? (
            <SvgFragment type="toggleOn" />
          ) : (
            <SvgFragment type="toggleOff" />
          )}
        </Container>

        {label && <Text $bold>{label}</Text>}
      </Wrapper>
    </TooltipWrapper>
  );
};

const Wrapper = styled.div<{ flex?: boolean }>`
  ${({flex}) => flex && `
    display: flex;
    align-items: center;
    gap: 4px;
  `}
`;

const Container = styled.div<{ isToggled: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
`;

export default ToggleButton;
